.slider-area.distance-slider {
    padding-left: 0.5rem;
    padding-right: 2rem;

    .ui-slider-handle {
        width: 2.5rem;
        height: 1.875rem;
        top: -0.5rem;
        border-radius: 5px;
        background-color: $white;
        cursor: pointer;
        font-size: 1.2em;
        font-weight: bold;
        color: $black;
        font-size: 0.875rem;
        font-weight: 600;
        outline: none;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &.ui-state-active {
            color: $grey-dark;
            border: 1px solid $green-light;
        }

        &::after {
            content: 'mi';
            position: absolute;
            top: 2.5rem;
            color: $grey-normal;
            font-weight: 400;
        }
    }

    .ui-widget.ui-widget-content {
        border: none;
        background-color: $blue-light;
        border-radius: 9999px;
    }

    .ui-slider-range {
        border: none;
        @extend %green-gradient-dark;
        border-radius: 9999px;
    }
}
