input,
select {
    outline: none;
}

textarea,
input:not([type='checkbox']):not([type='radio']),
select,
.rounded-input {
    min-width: 16.875em;
    width: 100%;
    color: $black;
    font-size: 0.875rem;
    border: solid 1px $grey;
    padding: 0 1.5em;
    transition: all 0.15s;

    &::placeholder {
        color: $black-alpha-50;
    }

    &:focus {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
        outline: none;
    }

    &:hover {
        border-color: $grey-normal;
    }

    &.border-green {
        border-color: $green-dark;
        border-width: 2px;
        // This should be a gradient which is doable with border-image. It is however incompatible with border-radius. Workaround?
        // https://css-tricks.com/gradient-borders-in-css/
        // border-image-source: linear-gradient(45deg, $green-light, $green-light-end);
        // border-image-slice: 1;
    }

    &.dirty:valid {
        border-color: $green;
    }

    &.dirty:invalid {
        border-color: $red;
    }
}

div.rounded-input {
    padding: 0;
}

textarea {
    padding: 1em 1.5em;
}

.rounded-input,
select,
input:not([type='checkbox']):not([type='radio']) {
    @extend %rounded-full;
}

label {
    font-size: 0.875rem;
}

.form-group {
    position: relative;

    &.with-icon {
        input.form-control[type='text'],
        input.form-control[type='email'],
        input.form-control[type='password'] {
            padding: $input-padding-with-icon;
        }
    }

    &.no-border {
        input.form-control[type='text'] {
            border: none;
        }
    }
}

.form-group-addon {
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);

    &.right {
        left: auto;
        right: 1em;
    }

    &.icon-green {
        color: $input-icon-green;
    }
}
