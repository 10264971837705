.p-0 {
    padding: 0;
}

.pl-0 {
    padding-left: 0;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.pr-2 {
    padding-right: 0.5rem;
}

.p-2 {
    padding: 0.5rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.p-3 {
    padding: 1rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pl-3 {
    padding-left: 1rem;
}

.px-3 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.py-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.p-4 {
    padding: 1.5rem;
}

.px-4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.pt-5 {
    padding-top: 3rem;
}

.px-5 {
    padding-left: 3rem;
    padding-right: 3rem;
}

.p-6 {
    padding: 1.5rem;
}

.p-8 {
    padding: 2rem;
}

.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.p-16 {
    padding: 4rem;
}

.px-16 {
    padding-right: 4rem;
    padding-left: 4rem;
}

.px-20 {
    padding-right: 5rem;
    padding-left: 5rem;
}

.mr-auto {
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mx-auto {
    margin-right: auto;
    margin-left: auto;
}

.m-0 {
    margin: 0;
}

.mr-0 {
    margin-right: 0;
}

.ml-0 {
    margin-left: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.ml-1 {
    margin-left: 0.25rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mb-2 {
    margin-bottom: 0.5rem;
}

.mt-2 {
    margin-top: 0.5rem;
}

.mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.ml-3 {
    margin-left: 1rem;
}

.mr-3 {
    margin-right: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mx-4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

.mt-5 {
    margin-top: 3rem;
}

.mb-5 {
    margin-bottom: 3rem;
}

.my-5 {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.m-8 {
    margin: 2rem;
}

.mb-8 {
    margin-bottom: 2rem;
}

.ml-8 {
    margin-left: 2rem;
}

.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mt-8 {
    margin-top: 2rem;
}

.mt-10 {
    margin-top: 2.5rem;
}

.mt-12 {
    margin-top: 3rem;
}

.mt-16 {
    margin-top: 4rem;
}

.mt-20 {
    margin-top: 5rem;
}

.mb-20 {
    margin-bottom: 5rem;
}

.mt-24 {
    margin-top: 6rem;
}

.my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    margin-left: 0.5rem;
    margin-right: 0rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    margin-left: 1rem;
    margin-right: 0rem;
}

.space-x-6 > :not([hidden]) ~ :not([hidden]) {
    margin-left: 1.5rem;
    margin-right: 0rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 1.5rem;
    margin-bottom: 0rem;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 2rem;
    margin-bottom: 0rem;
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 3rem;
    margin-bottom: 0rem;
}
