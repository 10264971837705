.grower-detail {
    h1 {
        @media (min-width: $screen-sm-min) {
            font-size: 3.75rem;
        }
    }

    .header-row {
        display: flex;
        flex-direction: column;

        > * + * {
            margin-top: 2rem;
        }

        @media (min-width: $screen-md-min) {
            flex-direction: row;

            > * + * {
                margin-top: 0;
                margin-left: 2.5rem;
            }
        }
    }

    .grower-image {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 320px;
            height: 320px;
        }

        img {
            width: 100%;
        }
    }

    .carousel-counter {
        width: 4ch;
        text-align: center;
    }

    .strains-filter {
        border-color: $green-light;

        .filter {
            cursor: pointer;
            height: 100%;
            color: $grey-normal;

            img {
                filter: grayscale(0.95);
            }
        }

        .filter.active {
            @extend %green-gradient-dark;
            color: $white;

            img {
                filter: grayscale(0);
            }
        }
    }

    .strains-wrapper {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 4rem 2rem;

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $screen-lg-min) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    .budtenders {
        .image-wrapper {
            width: 70px;
            height: 70px;
        }
    }
}
