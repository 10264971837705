.login {
    h1,
    .footer {
        text-align: center;
    }

    .footer-row {
        min-height: 4em;
    }

    .form {
        margin: 2em 0;
    }

    .form-group,
    input:not([type='checkbox']),
    select {
        width: 100%;
        max-width: 23.125rem;
    }

    .form-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1em;
        flex-wrap: wrap;
    }

    .form-row.check {
        margin-bottom: 0.5em;
    }

    .form-check {
        display: flex;
        width: 100%;
        max-width: 23.125rem;
        padding: 0;
    }

    .existing-user {
        font-size: 1.125rem;
        font-weight: normal;
    }

    .error-icon {
        display: none;
        color: $red;
        margin-right: 1em;
    }

    .btn-sign-out {
        margin-top: 3em;
    }

    .forgot-password-link {
        margin-top: 1em;
        color: #797979;

        i {
            margin-right: 0.7em;
        }
    }

    .loader {
        position: absolute;
        transform: translateX(-100px);
    }
}

.almost-done-wrapper {
    margin-top: 2rem;
    margin-bottom: 6rem;
    color: $grey-normal;
    font-size: 1.125rem;
    line-height: 2;
}
