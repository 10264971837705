.legal-wrapper {
    h2,
    h3 {
        color: $black;
        margin-bottom: 1em;
        font-weight: 700;
    }

    h2 {
        margin-top: 2em;
        font-size: 1.5rem;
    }

    h3 {
        margin-top: 1em;
        font-size: 1.2rem;
    }

    .paragraph {
        @extend %font-family-light;
        text-align: justify;
        margin: 1em 0;
    }

    .sub-paragraph {
        margin: 1em 0;
    }

    ol {
        padding-left: 1.5em;
        margin: 0.5em 0;

        li {
            margin: 0.5em 0;
        }
    }
}
