.strain-list-item-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .strain-item-main {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: flex-start;

            > * + * {
                margin-left: 1.5rem;
            }
        }

        .image-wrapper {
            width: 100%;
            text-align: center;

            .image {
                width: 100%;
                max-height: 12.5rem;
            }

            @media (min-width: $screen-sm-min) {
                width: 10.625rem;
                height: auto;
            }
        }
    }

    .strain-item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 0.5rem;

        > * + * {
            margin-top: 0.5rem;
        }

        @media (min-width: $screen-sm-min) {
            align-items: flex-start;
            text-align: left;
        }
    }

    .strain-item-additional {
        display: flex;
        flex-direction: column;

        > * + * {
            margin-top: 1rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            margin-top: 2rem;

            > * + * {
                margin-top: 0;
                margin-right: 1rem;
            }
        }
    }

    .similar-strain-location-btn {
        @extend .btn;
        width: 100%;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            margin-left: 0;
            margin-right: 0.5rem;
        }

        &.unavailable {
            background-color: $white;
            color: $grey-normal;
            border: 1px solid $separator-color;
        }

        @media (min-width: $screen-sm-min) {
            width: 10.625rem;
        }
    }

    .strain-item-terpenes {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media (min-width: $screen-sm-min) {
            order: 1;
            justify-content: flex-start;
        }
    }

    .terpene-tooltip {
        display: none;
        background: #555555;
        border-radius: 15px 15px 15px 0px;
        position: absolute;
        color: $white;
        top: -2.2rem;
        left: 1rem;
        padding: 0.3rem 1.2rem;
        z-index: 10;
    }
}
