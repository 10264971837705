.pac-container {
    font-family: 'Raleway';
    margin-top: 10px;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    color: $black;

    &.pac-logo:after {
        background-image: none !important;
        padding: 0;
        height: 0;
    }

    .pac-item {
        border-top: none;
        padding: 0.5em 1.5em;

        span {
            font-size: 14px !important;
            font-weight: 300;
        }

        .pac-icon.pac-icon-marker {
            margin-right: 15px;
        }

        .pac-item-query, .pac-icon-marker {
            font-weight: 600;
        }
    }
}
