// These are used in advanced search form and results -
// the colors are different than those used in the guide
.terpenes-circle {
    $circle-size: 1.75rem;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    font-weight: semibold;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    margin-right: 1rem;
    background-color: var(--terpene-bg-color);

    &:hover {
        .terpene-tooltip {
            display: block;
        }
    }
}

.humulene {
    --terpene-bg-color: #702988;
}

.pinene {
    --terpene-bg-color: #685005;
}

.linalool {
    --terpene-bg-color: #1355a7;
}

.caryophyllene {
    --terpene-bg-color: #e64d10;
}

.myrcene {
    --terpene-bg-color: #056568;
}

.terpinolene {
    --terpene-bg-color: #a0278b;
}

.ocimene {
    --terpene-bg-color: #81a406;
}

.limonene {
    --terpene-bg-color: #087128;
}

.camphene {
    --terpene-bg-color: #b665b9;
}

.terpineol {
    --terpene-bg-color: #2a9b92;
}

.phellandrene {
    --terpene-bg-color: #d1ab36;
}

.carene {
    --terpene-bg-color: #393425;
}

.pulegone {
    --terpene-bg-color: #ef4f4f;
}

.sabinene {
    --terpene-bg-color: #130568;
}

.geraniol {
    --terpene-bg-color: #95b2c3;
}

.valencene {
    --terpene-bg-color: #ff7a00;
}
