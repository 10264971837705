/** format */
.footer-region {
    padding: 1rem 1rem;
    text-align: center;

    a {
        color: $black-alpha-50;
    }

    .links {
        max-width: 50rem;
        margin: 1em auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        color: $black-alpha-50;
        font-weight: 600;
        list-style: none;
        padding-left: 0;

        @media (min-width: $screen-xs-min) {
            flex-direction: row;
        }

        li {
            margin: 0.6rem 0;

            @media (min-width: $screen-xs-min) {
                margin: 0;
            }
        }

        .separator {
            display: none;

            @media (min-width: $screen-xs-min) {
                display: block;
            }

            &:after {
                color: $black-alpha-50;
            }
        }
    }

    .copyright-wrapper {
        color: $black-alpha-50;
        padding: 1em 0;
    }

    .vendor-signup {
        a {
            color: $avocado-green;
        }

        a:hover {
            color: $paradiso;
        }
    }
}
