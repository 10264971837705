input[type='checkbox'],
input[type='radio'] {
    appearance: none;
    height: 24px;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    &::after {
        font-family: FontAwesome;
        opacity: 0;
        transition: opacity 0.1s;
    }

    &:checked {
        &::after {
            opacity: 1;
        }
    }
}

input[type='checkbox'] {
    background-color: $grey;
    border-radius: 5px;
    padding: 0;

    &::after {
        font-size: 12px;
        content: '\f00c';
        color: $white;
    }

    &:checked {
        background-color: $green-light;
    }
}

input[type='radio'] {
    border-radius: 50%;
    border: 1px solid $grey;

    &::after {
        font-size: 14px;
        content: '\f111';
        color: $green-light;
        border-radius: 50%;
    }

    + label {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}
