.location-lookup {
    display: flex;
    justify-content: center;

    @media (min-width: $screen-xs-min) {
        justify-content: flex-start;
    }

    form {
        width: 100%;

        @media (min-width: $screen-xs-min) {
            max-width: 18rem;
        }
    }
}
