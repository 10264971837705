.strain-detail-wrapper {
    min-height: 50rem;

    .back-to-search-wrapper {
        margin-top: 2rem;
        font-size: 0.875rem;

        i {
            margin-right: 0.5rem;
        }
    }

    .details-header {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .strain-data {
        .strain-data-detail {
            display: flex;
            align-items: center;
        }
    }

    .strain-variety {
        height: 1.875rem;
        width: 1.875rem;

        @media (min-width: $screen-sm-min) {
            height: 3.125rem;
            width: 3.125rem;
        }
    }

    .strain-name {
        font-weight: 300;
        font-size: 2.25rem;

        @media (min-width: $screen-sm-min) {
            font-size: 3.75rem;
        }
    }

    .match-score-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.125rem;

        @media (min-width: $screen-sm-min) {
            flex-direction: column;
            background-color: rgba(162, 209, 63, 0.08);
            border-radius: 1rem;
            padding: 1.25rem;
            margin-top: 0;
        }

        .score-value {
            font-size: 1.375rem;
            margin-left: 0.5rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.375rem;
                margin-left: 0;
            }
        }
    }

    .recommendation-box {
        align-self: center;
        margin-top: 1rem;
        padding: 0.75rem 1rem;

        @media (min-width: $screen-sm-min) {
            margin-top: 0;
            align-self: stretch;
            padding: 1.875rem;
        }
    }

    .breadcrumbs {
        display: none;

        @media (min-width: $screen-sm-min) {
            display: block;
        }
    }

    .main-details {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;

            > * + * {
                margin-left: 2.25rem;
            }
        }
    }

    .strain-photo-wrapper {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 35%;
            flex-shrink: 0;
        }

        .slick-dots {
            margin-top: 1rem;
        }
    }

    .main-image-carousel {
        min-height: 403px;

        img {
            object-fit: contain;
        }
    }

    .image-nav {
        display: none;

        @media (min-width: $screen-sm-min) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rating-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1rem;

        > * + * {
            margin-top: 1.875rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 0;

            > * + * {
                margin-top: 0rem;
            }
        }
    }

    .lineage-and-flavors {
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;

        > * + * {
            margin-top: 3.125rem;
        }

        @media (min-width: $screen-md-min) {
            flex-direction: row;
            column-gap: 2rem;

            > div {
                width: 50%;
            }

            > * + * {
                margin-top: 0;
                margin-left: 2rem;
            }
        }
    }

    .lineage-region,
    .flavors-region {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.25rem;

        // Workaround low support for flexbox gap
        // column-gap: 2rem;
        margin-left: -2rem;
        width: calc(100% + 2rem);

        > * {
            margin-left: 2rem;
        }

        @media (min-width: $screen-sm-min) {
            margin-top: 1.875rem;
        }
    }

    .ingredients-wrapper {
        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-wrap: wrap;
        }

        .item {
            justify-content: space-between;

            @media (min-width: $screen-sm-min) {
                justify-content: flex-start;
                &:not(:last-child) {
                    :last-child::after {
                        content: ',';
                    }
                }
            }
        }
    }

    .effects-and-benefits {
        > * + * {
            margin-top: 2.5rem;
        }

        @media (min-width: $screen-md-min) {
            display: flex;

            > div {
                width: 33.33%;
            }

            > * + * {
                margin-top: 0;
                margin-left: 2.5rem;
            }
        }
    }

    .effect {
        display: flex;
        flex-shrink: 0;
        position: relative;
        height: 6px;
        border: 0;
        background-color: $white-smoke;

        span {
            border: 0;
            border-left: 1px solid $white;
        }

        .handle {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            height: 1.5rem;
            width: 1.5rem;
            color: $white;
        }

        .point {
            flex-grow: 1;
            --radius: 0.25rem;

            &:nth-child(2) {
                border-top-left-radius: var(--radius);
                border-bottom-left-radius: var(--radius);
            }
            &:last-child {
                border-top-right-radius: var(--radius);
                border-bottom-right-radius: var(--radius);
            }
        }
    }

    .similar-strains {
        h3 {
            text-align: center;

            @media (min-width: $screen-sm-min) {
                text-align: left;
            }
        }
    }

    .similar-strains-wrapper {
        display: flex;
        flex-direction: column;

        > * + * {
            margin-top: 2rem;
        }

        @media (min-width: $screen-md-min) {
            flex-direction: row;
            justify-content: space-between;

            > * + * {
                margin-top: 0;
                margin-left: 1rem;
            }
        }

        @media (min-width: $screen-md-min) {
            > :nth-of-type(n + 4) {
                display: none;
            }

            > .strain-list-item-wrapper {
                width: 33.33%;
            }
        }
    }

    .strain-reviews-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $screen-md-min) {
            flex-direction: row;
            align-items: flex-start;

            > * + * {
                margin-left: 3.5rem;
            }
        }
    }

    .rate-strain-form {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: auto;
        }
    }

    .growing-wrapper {
        text-align: center;
        margin-right: 3.125em;

        &:last-child {
            margin-right: 0;
        }

        .flavor {
            height: 3.125rem;
            width: 3.125rem;
        }
    }

    .strain-growing-section {
        .text-left {
            text-align: left;
        }
        .growing-wrapper {
            height: 8em;
            min-width: 10em;
            width: auto;
            padding: 0.5em;

            img {
                width: 3.7em;
            }

            .flavor-name {
                font-size: 0.8em;
            }

            .flavor {
                padding: 0.5em;
            }
        }
    }
}

.upload-image-dialog {
    form {
        .form-actions {
            margin-top: 2em;
        }

        .upload-image {
            width: 0;
            height: 0;
            border: 0;
            min-width: 0;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }
}

.strain-review-dialog {
    .payload-effect {
        a {
            color: $grey-normal;

            &:hover {
                background-color: $blue-light;
                color: $black;
            }
        }
    }

    .effect-slider {
        flex-grow: 1;
    }

    .effect {
        display: flex;
        flex-shrink: 0;
        height: 6px;
        position: relative;

        > span {
            cursor: pointer;
            flex-grow: 1;
        }

        > span:not(:first-child) {
            border-left: 1px solid $white;
        }

        > span:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }

        > span:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }

    .remove-effect,
    .btn-add-effect {
        .fa {
            margin-left: 0;
        }
    }
}

.location-controls {
    width: 100%;

    @media (min-width: $screen-sm-min) {
        width: auto;
    }
}

.popup-container {
    .fa-map-marker {
        margin-left: 0;
    }
}

.availability-popup {
    position: absolute;
    right: 0;
    left: 0;
    background: $white;
    z-index: 5;
    overflow-x: auto;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    @media (min-width: $screen-sm-min) {
        right: 0;
        left: auto;
    }

    table {
        a {
            color: $blue;
        }

        th,
        td {
            padding: 1rem 2rem;
            min-width: 9rem;

            &:first-child {
                min-width: 18rem;
            }
        }

        i {
            margin-left: 0.75rem;
            color: $grey;
        }

        thead tr {
            display: block;
            cursor: pointer;
        }

        tbody {
            display: block;
            overflow-y: auto;
            max-height: 18rem;
        }
    }

    .prices-wrapper {
        position: absolute;
        right: 0;
        top: 3rem;
        background: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        .price {
            display: block;
            cursor: pointer;
            padding: 0.5rem 2rem;
        }

        .price:hover {
            color: $iris-blue;
        }
    }

    .message {
        margin: 1em;
        text-align: center;
        font-size: 1.5em;
    }
}
