.form-dialog {
    position: relative;

    form {
        .form-actions {
            display: block;
            position: absolute;
            bottom: 0.5em;
            width: 100%;
            text-align: center;
        }
    }
}

.ui-dialog {
    font-family: 'proxima_regular', sans-serif;
    border-radius: 0.625rem;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    padding: 0 4rem;

    button {
        font-family: 'proxima_regular', sans-serif;
        font-size: 0.875rem;
    }

    textarea {
        font-family: 'proxima_light', sans-serif;
        font-size: 0.9375rem;
        resize: none;
    }

    &.ui-widget-content {
        border: 0;
    }

    .ui-dialog-titlebar {
        padding: 1rem;
        position: static;
    }

    .ui-dialog-titlebar-close {
        top: 1rem;
        right: 1rem;
        margin: 0;
        border: 0;
        border-radius: 9999px;
        height: 1.875rem;
        width: 1.875rem;
        background: none;

        .ui-icon {
            background: url(../images/ic_close.svg) no-repeat center center;
        }
    }

    .ui-dialog-title {
        font-size: 1.125rem;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        white-space: normal;
    }

    .ui-dialog-content {
        padding: 0;

        .dialog-actions {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
}

.ui-widget-header {
    background: none;
    border: none;
}

.confirm-dialog,
.rate-dialog {
    &.ui-dialog-content {
        overflow: visible;
    }
}

.rate-dialog {
    form {
        .review-textarea-group {
            margin-top: 2em;
            text-align: left;

            label {
                display: block;
                font-size: 1.5em;
                margin-bottom: 1em;
            }
        }

        .rate-stars {
            margin: 0 auto;
        }
    }
}

#eligible-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 36rem;
    background-color: $white;
    padding: 2rem;
    text-align: center;
    z-index: $z-index-popup;
}

.dialog-backdrop {
    position: fixed;
    inset: 0;
    z-index: $z-index-nav;
    background-color: rgba(0, 0, 0, 0.5);
}
