.slick-dots {
    list-style: none;
    padding-left: 0;
    display: flex;
    justify-content: center;

    button {
        color: transparent;
        background: transparent;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        border: 1px solid $grey;
        font-size: 0;
        line-height: 0;
        display: block;
        padding: 0;
        cursor: pointer;
        outline: none;
        margin: 0 0.1563rem;
    }

    .slick-active {
        button {
            background-color: $green-light;
        }
    }
}

.slick-slide {
    &:focus {
        outline: none;
    }
}
