.strains-root-container {
    h1 {
        @media (min-width: $screen-sm-min) {
            font-size: 3.75rem;
        }
    }

    .types-wrapper {
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }

    .origins-map {
        width: 100%;
        max-width: 910px;
    }

    .strains-example-wrapper {
        margin-bottom: 3em;

        .list {
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(1, minmax(0, 1fr));

            @media (min-width: $screen-sm-min) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media (min-width: $screen-md-min) {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }

        .list-loader {
            text-align: center;
            margin-top: 3rem;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .strains-root-container {
        .strains-example-wrapper {
            margin-bottom: 3em;
        }
    }
}
