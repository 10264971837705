%font-family-default {
    font-family: 'proxima_regular', sans-serif;
}

%font-family-light {
    font-family: 'proxima_light', sans-serif;
}

%rounded-full {
    border-radius: 9999px;
}

%green-gradient-light {
    background: linear-gradient(
        92.24deg,
        rgba($green-light, 0.2) 1.88%,
        rgba($green-trees, 0.2) 98.12%
    );
}

%green-gradient-dark {
    background: linear-gradient(
        92.24deg,
        rgba($green-light, 1) 1.88%,
        rgba($green-trees, 1) 98.12%
    );
}
