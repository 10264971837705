.profile-tabs {
    border: 1px solid $green-light;
    font-size: 0.5rem;
    list-style: none;
    padding-left: 0;
    @extend %rounded-full;
    display: flex;
    justify-content: space-between;

    @media (min-width: $screen-xs-min) {
        font-size: 0.6875rem;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 0.875rem;
    }

    .profile-tab {
        height: 1.5rem;
        flex-grow: 1;
        text-transform: uppercase;
        @extend %rounded-full;

        @media (min-width: $screen-sm-min) {
            height: 2.5rem;
        }

        a {
            color: $grey-normal;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: inherit;
        }
    }

    .profile-tab.active {
        @extend %green-gradient-dark;

        a {
            color: $white;
        }
    }
}
