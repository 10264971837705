.inset-0 {
    inset: 0;
}

.top-0 {
    top: 0;
}

.top-32 {
    top: 8rem;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

.resize-none {
    resize: none;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.fixed {
    position: fixed;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.opacity-0 {
    opacity: 0;
}

.block {
    display: block;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.inline-block {
    display: inline-block;
}

.disabled {
    pointer-events: none;
    cursor: default;
}

.align-middle {
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-events-none {
    pointer-events: none;
}

.noselect {
    user-select: none;
}

.appearance-none {
    appearance: none;
}

.bg-transparent {
    background-color: transparent;
}

.bg-green-gradient-dark {
    background: linear-gradient(
        92.24deg,
        rgba($green-light, 1) 1.88%,
        rgba($green-trees, 1) 98.12%
    );
}

.bg-black-less {
    background-color: $black-less;
}

.bg-blue-gradient {
    background: linear-gradient(92.24deg, #1da1f2 1.88%, $blue 98.12%);
}

.bg-white {
    background-color: $white;
}

.bg-white-smoke {
    background-color: $white-smoke;
}

.bg-grey {
    background-color: $grey;
}

.bg-green {
    background-color: $green-light;
}

.bg-hollow-green {
    background-color: $hollow-green;
}

.bg-green-wizard {
    background-color: $green-wizard;
}

.bg-red {
    background-color: $missing-red;
}

.bg-blue-light {
    background-color: $blue-light;
}

.bg-blue-light-alpha {
    background-color: $blue-light-alpha;
}

.hover\:bg-blue-light {
    &:hover {
        background-color: $blue-light;
    }
}

.object-contain {
    object-fit: contain;
}

.object-cover {
    object-fit: cover;
}

.list-style-none {
    list-style: none;
}

.list-style-dash {
    list-style: none;
    padding-left: 0;
}

.list-style-dash > li:before {
    content: '-';
    margin-right: 1rem;
}

.xs\:block {
    @media (min-width: $screen-xs-min) {
        display: block;
    }
}

.sm\:block {
    @media (min-width: $screen-sm-min) {
        display: block;
    }
}

.md\:block {
    @media (min-width: $screen-md-min) {
        display: block;
    }
}

.sm\:inline-block {
    @media (min-width: $screen-sm-min) {
        display: inline-block;
    }
}

.xs\:flex {
    @media (min-width: $screen-xs-min) {
        display: flex;
    }
}

.sm\:flex {
    @media (min-width: $screen-sm-min) {
        display: flex;
    }
}

.xs\:hidden {
    @media (min-width: $screen-xs-min) {
        display: none;
    }
}

.sm\:hidden {
    @media (min-width: $screen-sm-min) {
        display: none;
    }
}

.md\:hidden {
    @media (min-width: $screen-md-min) {
        display: none;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}
