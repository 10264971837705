.home {
    h1 {
        font-size: 2.25rem;

        @media (min-width: $screen-md-min) {
            font-size: 3.75rem;
        }
    }

    p {
        font-size: 0.875rem;

        @media (min-width: $screen-md-min) {
            font-size: 1.125rem;
        }
    }

    section {
        height: 32rem;
    }

    .home-row-01 {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.8) 0%,
                rgba(0, 0, 0, 0) 100%
            ),
            url(../images/home/weed_dark-xxs.jpg);

        @media (min-width: $screen-xs-min) {
            background-image: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                url(../images/home/weed_dark-xs.jpg);
        }

        @media (min-width: $screen-sm-min) {
            background-image: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                url(../images/home/weed_dark-sm.jpg);
        }

        @media (min-width: $screen-md-min) {
            background-image: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 0) 100%
                ),
                url(../images/home/weed_dark.jpg);
        }

        .main-content {
            flex-direction: column;

            @media (min-width: $screen-md-min) {
                flex-direction: row;
            }

            & > div {
                width: 100%;

                @media (min-width: $screen-md-min) {
                    width: 50%;
                    padding: 0 1rem;
                }
            }
        }

        .lookup-widget {
            height: 7.25rem;

            h2 {
                font-size: 0.875rem;

                @media (min-width: $screen-md-min) {
                    font-size: 0.9375rem;
                }
            }

            .typewriter {
                font-size: 1.5rem;

                @media (min-width: $screen-md-min) {
                    font-size: 2.125rem;
                }
            }
        }
    }

    .home-row-02 {
        background-position: bottom left;
        background-color: $white;
        background-repeat: no-repeat;
        background-size: auto 65%;
        background-image: url(../images/home/weed_flower-xxs.jpg);

        @media (min-width: $screen-xs-min) {
            background-image: url(../images/home/weed_flower.jpg);
        }

        .container {
            justify-content: center;
            align-items: flex-start;

            @media (min-width: $screen-sm-min) {
                justify-content: flex-end;
                align-items: center;
            }

            .search-box {
                width: 100%;

                @media (min-width: $screen-md-min) {
                    width: 50%;
                }
            }
        }
    }

    .home-row-03 {
        background: $white url(../images/home/mobile-map-pin.png) no-repeat;
        background-position: right 90%;
        background-size: contain;

        .container {
            @media (min-width: $screen-sm-min) {
                justify-content: center;
            }
        }

        .dispensary-lookup-form {
            flex-direction: column;

            & > * + * {
                margin-top: 1rem;
            }

            @media (min-width: $screen-sm-min) {
                flex-direction: row;

                & > * + * {
                    margin-top: 0;
                    margin-left: 3rem;
                }
            }

            > div {
                @media (min-width: $screen-sm-min) {
                    width: 23.75rem;
                }
            }
        }
    }

    .location-hero-grower {
        input {
            height: 3.25rem;
        }
    }
}

.verify-email-dialog {
    a {
        color: $iris-blue;
    }
}
