.strain-404-container {
    .content {
        ul {
            padding-left: 1em;

            li {
                margin-bottom: 0.2em;
            }
        }
    }

    .background {
        background: url('../images/404-background.jpeg') no-repeat top center;
        background-size: cover;
        height: 260px;
        margin-top: 3rem;

        @media (min-width: $screen-sm-min) {
            height: 540px;
        }
    }
}
