.business-account-container {
    .business-account-header {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            align-items: normal;

            > * + * {
                margin-left: 2rem;
            }
        }

        .business-image {
            max-width: 14rem;
            max-height: 14rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .header-menu-item {
            i {
                color: $iris-blue;
            }
        }
    }

    .locations-form,
    .details-form,
    .menu-form {
        .form-field {
            margin-bottom: 1rem;
        }

        h3 {
            font-weight: 600;
            color: $black;
        }

        label {
            color: $grey-normal;
        }

        .distance-slider {
            padding-left: 0;
        }

        .upload-image-btn {
            color: $white;
        }

        input:not([type='checkbox']),
        select {
            height: 3.25rem;
            min-width: initial;
        }

        textarea {
            height: 12rem;
        }

        .ext-input {
            min-width: initial;
            width: 6rem;
        }

        .business-type-selection,
        .grow-methods-selection {
            @media (min-width: $screen-xs-min) {
                display: flex;
            }

            label {
                display: flex;
                align-items: center;
                flex-grow: 1;
            }
        }

        .btn-trash {
            border-color: $red;

            &:hover:not([disabled]) {
                background: $grey-normal;
            }
        }

        .hours-select {
            height: initial;
        }
    }

    .business-account-tab-content {
        .menu-strains-wrapper {
            h2 {
                margin: 1em 0;
            }

            .menu-header-wrapper,
            .menu-item-wrapper {
                display: flex;
                line-height: 3em;
            }

            .menu-header-wrapper {
                font-weight: 600;
            }

            .header-item,
            .menu-item {
                width: 6rem;
                text-align: center;
            }

            .strains {
                flex-grow: 1;
                text-align: left;
                width: 40rem;
            }

            input[type='checkbox'] {
                margin-right: 0;
                display: inline-flex;
            }

            .actions {
                width: 2rem;
            }
        }

        .btn-remove-menu-item,
        .delete-partner {
            cursor: pointer;
            color: $iris-blue;
            font-size: 1.5em;
        }
    }

    .business-analytics {
        margin-top: 1em;

        .overall-section {
            table {
                width: 100%;
                border-collapse: collapse;
            }

            table,
            td,
            th {
                border: 1px solid $grey;
            }

            td,
            th {
                padding: 1em;
            }
        }
    }
}

.daterangepicker {
    margin-top: 0;
    margin-right: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

    .drp-buttons {
        .btn {
            width: 6rem;
            font-weight: 300;
        }

        .btn-default {
            background-color: $white;
            border: 1px solid $grey;
        }
    }

    .calendar-table {
        td,
        th {
            padding: 4px;
        }

        td.in-range {
            background-color: $green-light;
            color: $white;
        }

        td.active {
            background-color: $green-trees;
        }
    }

    .ranges {
        li.active {
            background-color: $green-light;
        }
    }
}
