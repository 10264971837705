//
// Variables
// --------------------------------------------------

//== Colors
$black: #000;
$black-less: #1b1b1b;
$black-alpha-50: rgba(0, 0, 0, 0.5);
$white: #fff;
$grey-dark: #2f3f4c;
$grey-blue: #4d5359;
$grey-normal: #8e8e93;
$grey-light: #aaa8a8;
$grey: #dddddd;
$grey-lighter: #efefef;
$white-smoke: #f4f4f4;
$missing-red: #e66464;
$red: #db3b39;
$light-red: #ff4646;
$dark-pink: #eed3d7;
$pink: #f2dede;
$paradiso: #508484;
$aero-blue: #bbe6d1;
$hollow-green: #e0f3b9;
$green: #008326;
$green-wizard: #3a8d37;
$green-trees: #518b36;
$green-atlantis: #a6cf42;
$green-light: #a2d13f;
$green-dark: #6bad3b;
$avocado-green: #6bc331;
$mint-green: #d6e9c6;
$iris-blue: #10b3dc;
$cyan-blue: #92e1f4;
$blue-light: #f0f5f8;
$blue-light-alpha: rgba(240, 245, 248, 0.5);
$blue: #00b2ff;
$blue-truth: #57a4ff;

//== Responsive Breakpoints
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 1024px;
$screen-lg-min: 1200px;
$screen-xl-min: 1500px;
$screen-xxl-min: 1920px;

//== Make sure there is a space between the `-` and the `1` with out the space SASS will produce the wrong css
$screen-xs-max: ($screen-xs-min - 1);
$screen-md-max: ($screen-md-min - 1);

//== Z-Index
$z-index-popup: 200;
$z-index-nav: 100;
$z-index-spinner: $z-index-nav - 1;

// Input border - local to input?
$input-padding-with-icon: 0.75em 0.75em 0.75em 2.75em;
$input-icon-green: $green-light;

// Border
$separator-color: #c4c4c4;
