.wizard-steps-wrapper {
    --circle-size: 3.125rem;

    color: $green-wizard;
    font-size: 1.25rem;
    max-width: 40.625rem;
    margin-top: 1rem;
    margin-bottom: 3rem;

    @media (min-width: $screen-xs-min) {
        margin-top: 3rem;
        margin-bottom: 5rem;
    }

    .step-connector-wrapper {
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: calc(var(--circle-size) / 2);
        padding-right: calc(var(--circle-size) / 2);
    }

    .step-connector {
        z-index: 0;
        background-color: $grey;
        width: 100%;

        &.passed {
            background-color: $green-light;
            height: 3px;
        }

        &.active {
            @extend %green-gradient-dark;
            height: 3px;
        }
    }

    .step-wrapper {
        font-weight: 600;

        .title {
            display: none;
            font-weight: 300;
            font-size: 0.9375rem;
            padding-top: 1.5rem;
            color: $grey-normal;
            text-align: center;
            white-space: nowrap;
            position: absolute;
            top: 3rem;
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: $screen-xs-min) {
                display: block;
            }
        }
    }

    .wizard-step {
        border: 1px solid $green;
        background: $white;
        width: var(--circle-size);
        height: var(--circle-size);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;

        &.active {
            border-width: 2px;

            & ~ .title {
                font-weight: 600;
                color: $black;
            }
        }

        &.finished,
        &.passed {
            border: 2px solid $green-light;
            color: $green-light;
            cursor: pointer;
        }

        &.disabled {
            cursor: default;
            color: $grey-normal;
        }
    }
}

// Special case since it has many steps
.sign-up-wrapper {
    .wizard-steps-wrapper {
        --circle-size: 1.75rem;
        font-size: 1rem;

        @media (min-width: $screen-xs-min) {
            --circle-size: 3.125rem;
            font-size: 1.25rem;
        }

        .wizard-step {
            &.finished,
            &.passed {
                cursor: default;
            }
        }
    }
}
