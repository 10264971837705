.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.flex-none {
    flex: none;
}

.flex-1 {
    flex: 1 1 0%;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-shrink {
    flex-shrink: 1;
}

.justify-center {
    justify-content: center;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.align-start {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}
