// TODO: Clean this file up. Probably most of it doesn't belong here.
//
// Global Styles
// --------------------------------------------------
html {
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    line-height: 1.5;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

img {
    vertical-align: middle;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

table {
    border-collapse: collapse;
}

fieldset {
    border: 0;
}

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .main-region {
        flex-grow: 1;
    }
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: $screen-md-min) {
        max-width: $screen-md-min - 20;
    }

    @media (min-width: $screen-lg-min) {
        max-width: $screen-lg-min - 20;
    }

    @media (min-width: $screen-xl-min) {
        max-width: $screen-xl-min - 20;
    }

    @media (min-width: $screen-xxl-min) {
        max-width: $screen-xxl-min - 20;
    }
}

.signup-confirm {
    width: 100%;
    text-align: center;
    min-height: 500px;
    display: inline-block;

    .green-bg {
        background: #06b57f;
        min-height: 280px;
        padding-top: 50px;
        font-family: 'proxima_regular';
        font-size: 26px;
        font-weight: normal;
        color: #515151;
        margin-bottom: 50px;

        h2 {
            font-size: 25px;
            color: #515151;
            display: inline-block;
            width: 100%;
            margin-bottom: 25px;
        }

        img {
            display: inline-block;
        }
    }

    p {
        font-family: 'proxima_regular';
        font-size: 20px;
        line-height: 30px;
        float: left;
        width: 100%;
        margin-bottom: 20px;

        b {
            font-family: 'proxima_regular';
        }
    }

    h3 {
        font-family: 'proxima_regular';
        display: inline-block;
        margin-bottom: 25px;

        span {
            color: #7cc576;
        }
    }
}

.separator:after {
    content: '|';
    padding: 0 0.5em;
}

.error-message {
    display: block;
    color: $red;
}

.success-message {
    display: block;
    color: $avocado-green;
}

#loading-spinner {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    color: $green-light;
    position: fixed;
    font-size: 5.625rem;
    inset: 0;
    z-index: $z-index-spinner;
}

#loading-spinner-new {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $green-light;
    background-color: $white;
    position: fixed;
    font-size: 5.625rem;
    inset: 0;
    z-index: $z-index-spinner;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
}

.form .form-field select {
    background-color: $white;
}

.slider {
    height: 8px;
}

.slider span {
    border-radius: 1em;
    display: inline-block;
    margin-top: -4px;
}

.removable,
.form .removable {
    background: url(../images/close_icon.png) no-repeat;
    background-size: 1.5em 1.5em;
    width: 1.5rem;
    height: 1.5rem;
    content: '';
}

.payloads-region {
    display: none;
    position: absolute;
    top: 3.5em;
    width: 100%;
    max-height: 22rem;
    overflow-y: auto;
    font-family: 'Raleway', sans-serif;
    font-size: 1rem;
    background-color: $white;
    color: $black;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 2;

    .search-payload {
        display: block;
        padding: 1rem;
        cursor: pointer;
        border-radius: inherit;

        .disp-name {
            font-size: 1.2rem;
        }

        img {
            margin-right: 1rem;
        }
    }

    .search-payload.active,
    .search-payload:hover {
        background-color: $aero-blue;
        color: $grey-dark;
        font-weight: 600;
    }
}

.strain-lookup-field {
    position: relative;
}

.breadcrumbs {
    margin-top: 1rem;
    margin-right: 1rem;

    ol {
        @extend %font-family-light;
        list-style: none;
        font-size: 0.9375rem;
        font-weight: 300;
        padding-left: 0;
        display: flex;
        overflow: hidden;
        white-space: nowrap;

        li {
            cursor: default;
            text-overflow: ellipsis;

            span {
                margin: 0 0.6rem;
            }
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotating {
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
