.menu-overflow-container {
    overflow-x: auto;
}

.menu-container {
    width: max-content;
    min-width: 100%;
}

.menu-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    align-self: stretch;

    li {
        a {
            color: $grey;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-weight: 600;
            height: 100%;
        }

        img {
            filter: grayscale(0.95);
        }

        &.active {
            a {
                color: $black;
            }

            img {
                filter: grayscale(0);
            }

            .tab-title {
                display: initial;
            }
        }
    }
}

.business-location-holder {
    .menu-overflow-container {
        margin-right: -15px;
    }

    .menu-container {
        padding-right: 15px;
    }

    .menu-tabs {
        li {
            border-bottom: 4px solid transparent;

            &.active {
                border-bottom: 4px solid $green-light;
            }
        }

        a {
            padding: 0 1.5rem;
        }

        .tab-title {
            display: none;

            @media (min-width: $screen-sm-min) {
                display: initial;
            }
        }
    }
}

.kiosk-dispensary {
    .menu-tabs {
        min-height: 78px;
        margin: 1.5rem 0;

        li {
            padding: 0 0.5rem;
            margin: 0 0.5rem;
            border-bottom: 2px solid $grey;

            &.active {
                border-bottom: 2px solid $green-light;
            }

            a {
                flex-direction: column;
            }

            .tab-title {
                margin-top: 0.5rem;
            }
        }
    }
}

.menu-table {
    white-space: nowrap;

    th,
    td {
        padding: 1rem;
    }

    th:first-child {
        width: 100%;
        max-width: 30vh;
        text-align: left;
        white-space: initial;

        @media (min-width: $screen-xs-min) {
            max-width: initial;
        }
    }

    td {
        min-width: 5.75rem;
        text-align: center;
    }

    .reviews-header {
        min-width: 8.25rem;
    }

    .review-stars-region {
        margin: 0 auto;
    }
}
