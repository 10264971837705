.cannabis-guide {
    .absorption-type {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }

    .absorption-image {
        flex: 1 0 100%;

        @media (min-width: $screen-sm-min) {
            flex: 0 0 30%;
            margin-right: 2rem;
        }
    }

    .cannabinoid-single-header {
        @media (min-width: $screen-sm-min) {
            margin-top: 2.5rem;
            display: flex;
            justify-content: space-between;
        }
    }
}

.thc {
    --ingredient-color: 104, 80, 5;
}

.cbd {
    --ingredient-color: 19, 85, 167;
}

.cbg {
    --ingredient-color: 5, 101, 104;
}

.cbc {
    --ingredient-color: 8, 113, 40;
}

.thcv {
    --ingredient-color: 160, 39, 139;
}

.cbn {
    --ingredient-color: 149, 178, 195;
}
