.hours-field-group {
    .day-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }

        .day-name {
            margin-bottom: 0;
        }

        .hours-select-wrapper {
            display: flex;
            width: 100%;

            @media (min-width: $screen-xs-min) {
                width: auto;
            }
        }

        .hours-select {
            min-width: 0;

            @media (min-width: $screen-xs-min) {
                min-width: 12rem;
            }
        }
    }
}
