.wizard-wrapper {
    max-width: 64rem;
    margin: 0 auto;

    .variant-selection-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;

        @media (min-width: $screen-xs-min) {
            flex-direction: row;
            justify-content: center;

            > * + * {
                margin-left: 1rem;
            }
        }
    }

    .form-field {
        @extend .btn;
        width: 100%;

        height: 8.75rem;
        border-radius: 20px;
        padding: 0 0.5rem;
        border: 1px solid $separator-color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: default;
        color: $grey-normal;

        @media (min-width: $screen-xs-min) {
            max-width: 12.5rem;
        }

        &:hover {
            @extend %green-gradient-light;
        }

        &.active,
        &.selected {
            @extend %green-gradient-light;
            border-color: $green-light;
        }

        &.disabled {
            background: $grey-lighter;
            color: $grey-light;
        }

        label {
            cursor: pointer;
            color: $black;
            margin-bottom: 0;
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                object-fit: contain;
                margin-bottom: 1rem;
            }
        }

        input {
            min-width: auto;
            width: 2ch;
            position: absolute;
            top: 6px;
            left: 8px;
            background: transparent;
            border: 0;
            line-height: 1;
            font-size: 1.25rem;
            padding: 0.4rem;
            color: $green-trees;
            font-weight: 600;
            z-index: 1;

            &::after {
                color: $green-dark;
                font-size: 0.9375rem;
            }
        }

        .removable {
            height: initial;
            width: initial;
            padding: 0.4rem;
            background: none;
            z-index: 1;
            position: absolute;
            top: 6px;
            right: 8px;
            cursor: pointer;

            i {
                // Don't interfere with clicking in the right place
                margin: 0;
            }
        }
    }

    .form-actions {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-top: 2rem;
        border-top: 1px solid $separator-color;
        text-align: center;

        > * + * {
            margin-left: 1rem;
        }
    }

    .btn-skip,
    .btn-step {
        @extend .btn;
        height: 2.5rem;
        width: 10.625rem;
    }

    .btn-skip {
        @extend .btn-secondary;
        color: $green-trees;
    }

    .btn-step {
        @extend .btn-primary;
    }

    .strain-check {
        margin-top: 1rem;

        label {
            font-weight: 600;
        }

        .type-info {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            display: flex;
            color: $green-light;
            cursor: pointer;
            padding: 0.75rem;
        }
    }

    .info-box {
        position: absolute;
        top: 100%;
        left: -1rem;
        right: -1rem;
        transform: translateY(1rem);
        padding: 1.5rem;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        background-color: $white;
        color: $black;
        border-radius: 20px;
        z-index: 2;
        cursor: default;

        @media (min-width: $screen-sm-min) {
            left: -50px;
            right: -50px;
            transform: translateY(1.875rem);
        }
    }

    .fields {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        justify-content: center;
        gap: 1.25rem 1.875rem;
        margin-bottom: 3rem;

        @media (min-width: $screen-xs-min) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media (min-width: $screen-md-min) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media (min-width: $screen-lg-min) {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }
    }

    .effect-config-box {
        flex-direction: column;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border: none;
        background-color: $white;
        border-radius: 1.25rem;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

        .step-label {
            margin-top: 2.5rem;
            justify-content: center;
            align-items: center;
            line-height: 2.5;
            background: $white-smoke;
            color: $grey-light;
            text-align: center;
            cursor: default;
        }

        .slider-wrapper {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
        }

        .slider {
            .point {
                background: $green-atlantis;
                height: 100%;
                flex-grow: 1;
                margin: 0;
                cursor: pointer;
                border-right: 1px solid $white;
                border-left: 1px solid $white;

                &.active {
                    color: $green-atlantis;
                    background-color: $green-wizard;
                }

                &:first-of-type {
                    border-radius: 0.25rem 0 0 0.25rem;
                }

                &:last-of-type {
                    border-radius: 0 0.25rem 0.25rem 0;
                }
            }
        }

        .ui-slider {
            width: 82%;
            height: 7px;
            border: 0;
            border-radius: 50%;
            display: flex;
        }

        .ui-slider-handle {
            height: 1.3125rem;
            width: 1.3125rem;
            background-color: $green-wizard;
            border-color: $white;
            top: -0.2rem;
            outline: none;
            color: $white;
            text-align: center;

            &:focus,
            &:hover {
                box-shadow: 0 0 2pt 1pt $iris-blue;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                background: none;
                height: 2.75rem;
                width: 2.75rem;
            }
        }
    }
}

.search-form-wrapper {
    margin: 2rem auto 0;
    text-align: center;

    @media (min-width: $screen-xs-min) {
        margin-bottom: 3rem;
    }
}

// Currently unused
.just-a-second-dialog {
    .dialog-text {
        height: 36%;
        line-height: 1.5em;
    }

    .additional-section {
        margin-top: 2em;

        input[type='checkbox'] {
            width: 2em;
        }
    }
}
