.pinene {
    --ingredient-color: 104, 80, 5;
}

.linalool {
    --ingredient-color: 19, 85, 167;
}

.myrcene {
    --ingredient-color: 5, 101, 104;
}

.limonene {
    --ingredient-color: 8, 113, 40;
}

.terpinolene {
    --ingredient-color: 160, 39, 139;
}

.geraniol {
    --ingredient-color: 149, 178, 195;
}

.ocimene {
    --ingredient-color: 129, 164, 6;
}

.caryophyllene {
    --ingredient-color: 230, 77, 16;
}

.valencene {
    --ingredient-color: 255, 122, 0;
}

.terpineol {
    --ingredient-color: 42, 155, 146;
}

.a-humulene {
    --ingredient-color: 112, 41, 136;
}
