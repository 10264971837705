.rounded-full {
    border-radius: 9999px;
}

.rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.rounded-md {
    border-radius: 0.375rem;
}

.rounded-lg {
    border-radius: 0.625rem;
}

.border-solid {
    border-style: solid;
}

.border-0,
.border-none {
    border-width: 0;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
    border: 0 solid;
    border-top-width: 1px;
    border-bottom-width: 0px;
}

.divide-grey > :not([hidden]) ~ :not([hidden]) {
    border-color: $grey;
}

.border {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-4 {
    border-width: 4px;
}

.border-t {
    border: 0 solid;
    border-top-width: 1px;
}

.border-t-4 {
    border: 0 solid;
    border-top-width: 4px;
}

.border-r {
    border-right-width: 1px;
}

.border-b {
    border: 0 solid;
    border-bottom-width: 1px;
}

.border-b-4 {
    border-bottom-width: 4px;
}

.border-grey {
    border-color: $grey;
}

.border-grey-dark {
    border-color: $separator-color;
}

.border-green-light {
    border-color: $green-light;
}

.border-green-dark {
    border-color: $green-dark;
}

.border-white {
    border-color: $white;
}

.border-white-smoke {
    border-color: $white-smoke;
}

.shadow-md {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}
