.font-proxima {
    font-family: 'proxima_regular', sans-serif;
}

.font-proxima-light {
    font-family: 'proxima_light', sans-serif;
}

.font-raleway {
    font-family: 'Raleway', sans-serif;
}

.bold,
.font-bold {
    font-weight: 700;
}

.blurred {
    text-shadow: 0 0 15px #10b3dc;
    filter: blur(5px);
}

.font-semibold {
    font-weight: 600;
}

.font-normal {
    font-weight: 400;
}

.font-light {
    font-weight: 300;
}

.underline {
    text-decoration: underline;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-grey {
    color: $grey-normal;
}

.text-black {
    color: $black;
}

.text-black-alpha-20 {
    color: rgba(0, 0, 0, 0.2);
}

.text-black-alpha-50 {
    color: $black-alpha-50;
}

.text-green {
    color: $green-light;
}

.text-green-dark {
    color: $green-dark;
}

.text-green-trees {
    color: $green-trees;
}

.text-white {
    color: $white;
}

.text-blue {
    color: $blue-truth;
}

.text-red {
    color: $light-red;
}

.break-words {
    overflow-wrap: break-word;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
