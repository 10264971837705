a.blurred {
    filter: blur(5px);
}

.results-dialog {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;

    width: 32rem;
    max-width: 92vw;
    padding: 1em 1.75em;

    border: 1px solid $grey;
    background-color: $white;

    z-index: $z-index-nav + 1;

    p,
    h1 {
        margin: 1em 0;
    }

    h1 {
        font-size: 1.4rem;
    }
}

.strain-search-wrapper {
    .search-header {
        display: flex;
        text-align: left;
        margin-top: 2rem;

        > div + div {
            margin-left: 2rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: column;
            align-items: center;

            > div + div {
                margin-top: 1rem;
                margin-left: 0;
            }
        }
    }

    .search-logo {
        width: 2.875rem;

        @media (min-width: $screen-sm-min) {
            width: auto;
        }

        img {
            width: 100%;
        }
    }

    .search-subtitle {
        font-size: 0.9375rem;
        font-weight: 300;

        @media (min-width: $screen-sm-min) {
            font-size: 1.125rem;
            font-weight: 400;
            text-align: center;
        }
    }

    .search-form {
        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-direction: column;
        }
    }

    .search-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > * + * {
            margin-top: 2rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            justify-content: center;

            & > * + * {
                margin-top: 0;
                margin-left: 2rem;
            }
        }

        button {
            width: 100%;

            @media (min-width: $screen-sm-min) {
                width: 10.625rem;
            }
        }
    }

    .search-input-wrapper {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 23.125rem;
        }

        .search-input {
            padding-left: 2.5rem;
            min-width: auto;
        }
    }

    .advanced-search-link {
        text-align: center;
        margin-top: 2rem;

        @media (min-width: $screen-sm-min) {
            margin-top: 3rem;
        }

        a {
            margin-top: 2rem;

            @media (min-width: $screen-sm-min) {
                width: 10.625rem;
                margin-top: 0;
            }
        }
    }
}

.strain-search-wrapper {
    .search-result,
    .similar-result {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem;

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $screen-lg-min) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}
