.search-results-container {
    margin-top: 2rem;

    @media (min-width: $screen-sm-min) {
        margin-top: 3rem;
    }

    .search-results-header {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-xs-min) {
            flex-direction: row;
            justify-content: space-between;
        }

        h1 {
            @media (min-width: $screen-md-min) {
                font-size: 3.75rem;
            }
        }
    }

    .btn-delievery {
        &:disabled {
            background: $white;
            color: $grey-normal;
            border: 1px solid $grey-normal;
        }
    }

    .filter-menu-label {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    .filter-menu {
        width: 100%;
        margin-top: 0.5rem;

        @media (min-width: $screen-xs-min) {
            width: 10.5rem;
            margin-top: 0;
        }

        select {
            appearance: none;
            width: 100%;
            min-width: auto;
        }
    }

    .search-results-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
            margin-top: 1rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;

            &:not(:last-child) {
                border-bottom: 1px solid $grey;
            }

            > div {
                margin-top: 0;
            }

            > div + div {
                margin-left: 2rem;
            }
        }

        .indoor-icon {
            display: flex;

            img {
                margin-right: 1rem;
                width: 32px;
                height: 32px;
            }

            @media (min-width: $screen-sm-min) {
                display: none;
            }

            @media (min-width: $screen-md-min) {
                display: flex;

                img {
                    width: 50px;
                    height: 50px;
                }

                span {
                    font-size: 1.125rem;
                }
            }
        }

        .match-score {
            order: -1;
            align-self: stretch;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $grey;
            margin-top: 0;
            min-width: 6rem;

            @media (min-width: $screen-sm-min) {
                order: 0;
                flex-direction: column;
                justify-content: center;
                border: 0;
            }
        }

        .score-percentage {
            font-size: 1.25rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.375rem;
            }
        }

        .strain-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (min-width: $screen-sm-min) {
                align-items: flex-start;
                flex-grow: 1;
            }

            .variety-img {
                vertical-align: text-bottom;
            }
        }

        .action-buttons {
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: center;

            a {
                width: 100%;

                @media (min-width: $screen-sm-min) {
                    width: 10.5rem;
                }
            }
        }
    }

    .search-result-footer-wrapper {
        text-align: center;
    }
}
