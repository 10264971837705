.business-landing {
    .landing-header {
        background-repeat: no-repeat;
        background-size: cover;
        height: 24rem;

        @media (min-width: $screen-sm-min) {
            height: 32rem;
        }

        .title {
            font-size: 1.875rem;
            line-height: 1.2;

            @media (min-width: $screen-sm-min) {
                font-size: 3.75rem;
            }
        }

        .subtitle {
            font-size: 1.25rem;
            line-height: 1.2;

            @media (min-width: $screen-sm-min) {
                font-size: 2.125rem;
            }
        }
    }

    .landing-content {
        max-width: 75rem;
    }

    .features {
        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .features-item {
        ul {
            list-style: none;
            padding-left: 0;

            li {
                font-size: 0.9375rem;
                font-weight: 300;
                @extend %font-family-light;
                display: flex;
                align-items: center;

                &::before {
                    font-family: FontAwesome;
                    content: '\f00c';
                    color: $green-light;
                    margin-right: 0.75rem;
                }
            }
        }
    }

    .benefits {
        margin-top: 2rem;

        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 5rem;

            > * + * {
                margin-top: 0;
            }
        }

        .benefits-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 1rem;
            padding-right: 1rem;

            @media (min-width: $screen-sm-min) {
                width: 33.33%;
            }

            @media (min-width: $screen-md-min) {
                width: 16.66%;
            }

            img {
                margin: 0 auto;
            }

            span {
                display: block;
            }
        }
    }

    .testimonials-wrapper {
        margin-top: 0;

        @media (min-width: $screen-md-min) {
            display: flex;
            margin-top: 4rem;

            > * + * {
                margin-left: 2rem;
            }
        }
    }

    .testimonial {
        width: 33.33%;
        padding: 1.75rem;

        &::before {
            content: '”';
            @extend %font-family-light;
            font-size: 6rem;
            line-height: 0;
            margin-top: 4rem;
            margin-bottom: 2rem;
            color: $green-light;
            display: flex;
            justify-content: center;
        }
    }

    .slick-arrow {
        position: absolute;
        top: 5rem;
        z-index: 1;
        padding: 0;
    }

    .slick-prev {
        left: 3rem;
    }

    .slick-next {
        right: 3rem;
    }

    .slick-slide {
        margin: 2rem;
    }

    .objective {
        @extend %font-family-light;
        text-align: center;

        .green-line {
            height: 5px;
            width: 50px;
            @extend %green-gradient-dark;
        }

        .overview {
            font-size: 1.5rem;

            @media (min-width: $screen-sm-min) {
                font-size: 2.125rem;
            }
        }
    }

    .profile {
        text-align: center;

        h2 {
            margin-bottom: 2rem;

            @media (min-width: $screen-sm-min) {
                font-size: 3.75rem;
            }
        }

        p {
            @media (min-width: $screen-sm-min) {
                font-size: 2.125rem;
            }
        }

        .phone-contact {
            font-size: 1.125rem;
        }
    }
}

.dispensaries-landing {
    .dispensaries-header {
        background-image: url(../images/dispensary/header-bg.jpg);
    }
}

.cultivators-landing {
    .cultivators-header {
        background-image: url(../images/cultivators/header-bg.jpg);
    }
}

.step-container {
    max-width: 75rem;

    h1 {
        margin-top: 2rem;

        @media (min-width: $screen-xs-min) {
            margin-top: 4rem;
        }
    }

    .features-list {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-xs-min) {
            margin-top: 3rem;
            flex-direction: row;
            justify-content: center;
        }
    }

    .feature {
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
        line-height: 1.1;

        img {
            width: 30px;
            height: 30px;
            margin-right: 1.25rem;

            @media (min-width: $screen-xs-min) {
                width: 70px;
                height: 70px;
                margin-right: 0;
            }
        }

        @media (min-width: $screen-xs-min) {
            flex-direction: column;
            align-items: center;
        }
    }

    form {
        max-width: 48rem;
        margin-left: auto;
        margin-right: auto;

        h3 {
            font-size: 0.875rem;
            font-weight: 600;
            color: $black;
            text-align: left;
        }
    }

    .payment-img {
        max-width: 370px;
        margin-left: auto;
        margin-right: auto;
    }

    fieldset {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 1.875rem;
        padding: 0;

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: repeat(3, minmax(0, 1fr));
            grid-auto-flow: column;
        }

        &.payment-method {
            @media (min-width: $screen-sm-min) {
                grid-template-rows: repeat(2, minmax(0, 1fr));
                grid-auto-flow: row;
            }
        }

        &.promo-code {
            grid-template-rows: repeat(1, minmax(0, 1fr));
        }
    }

    .signup-form-1 {
        .country-list {
            list-style: none;
            padding-left: 0;
            background-color: $white;
            margin-top: 0.25rem;
            position: absolute;
            left: 0;
            right: 0;

            > li {
                border-radius: inherit;

                &:hover {
                    background-color: $aero-blue;
                    color: $grey-dark;
                }
            }
        }

        .country-div,
        .country-list > li {
            padding: 0.5rem 1.25rem;
            display: flex;
            align-items: center;
            font-size: 0.875rem;

            img {
                margin-right: 1rem;
            }
        }
    }

    .orders-title {
        font-size: 1.125rem;
    }

    .orders {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }

        .total {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: $screen-sm-min) {
                flex-direction: column;
                justify-content: flex-start;
                text-align: right;
            }
        }
    }
}
