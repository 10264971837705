.cannabis-guide {
    .container {
        max-width: 1170px;
    }

    h1 {
        font-size: 1.5rem;

        @media (min-width: $screen-sm-min) {
            font-size: 3.75rem;
        }
    }

    .main-title {
        @media (min-width: $screen-sm-min) {
            font-size: 2.125rem;
        }
    }

    .main-subtitle {
        font-size: 0.9375rem;

        @media (min-width: $screen-sm-min) {
            font-size: 1.125rem;
        }
    }

    .subtitle {
        font-style: italic;
        font-size: 1.125rem;
        font-weight: 300;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            align-self: stretch;
            width: 5px;
            margin-right: 0.4rem;
            @extend %green-gradient-dark;
        }
    }

    .section-title {
        display: flex;
        align-items: center;

        h2 {
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 600;
            margin: 0;

            @media (min-width: $screen-sm-min) {
                font-size: 1.25rem;
            }
        }

        hr {
            @extend %green-gradient-dark;
            height: 2px;
        }
    }

    .section-content {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;

            & > div + div {
                margin-left: 2rem;
            }
        }

        .strain-image {
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                width: 16.875rem;
                height: 100%;
                object-fit: contain;
            }
        }

        .btn-look-product {
            margin: 1rem auto;

            @media (min-width: $screen-sm-min) {
                margin: 1rem 0;
                order: 1;
            }

            &:hover {
                color: $white;
            }
        }
    }

    .guide-categories-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 4rem;

        & > li {
            flex-basis: 100%;
            margin-bottom: 4rem;
            padding: 0 1rem;

            @media (min-width: $screen-sm-min) {
                flex-basis: 50%;
            }

            @media (min-width: $screen-md-min) {
                flex-basis: 33.33%;
            }
        }
    }

    .ingredients-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-left: 0;

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
        }
    }

    .ingredients-list-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2rem 1rem;

        @media (min-width: $screen-sm-min) {
            flex: 0 0 50%;
        }

        @media (min-width: $screen-md-min) {
            flex: 0 0 33.33%;
        }

        @media (min-width: $screen-lg-min) {
            flex: 0 0 25%;
        }

        @media (min-width: $screen-xl-min) {
            flex: 0 0 16.66%;
        }
    }

    .ingredient-symbol {
        border-radius: 50%;
        height: 4.375rem;
        width: 4.375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(var(--ingredient-color), 1);
        background-color: rgba(var(--ingredient-color), 0.1);
        font-weight: 600;
        margin: 0 auto;
    }

    .ingredient-details,
    .cannabinoid-details {
        margin-top: 2rem;
        justify-content: space-between;

        @media (min-width: $screen-sm-min) {
            flex-direction: row-reverse;
        }
    }

    .ingredient-boiling-point,
    .cannabinoid-boiling-point {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $screen-sm-min) {
            margin-left: 4rem;
        }
    }
}
