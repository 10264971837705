table.alternating-colors {
    tr {
        &:nth-child(odd) {
            th,
            td {
                &:nth-child(odd) {
                    background-color: $white;
                }

                &:nth-child(even) {
                    background-color: $blue-light-alpha;
                }
            }
        }

        &:nth-child(even) {
            th,
            td {
                &:nth-child(odd) {
                    background-color: $blue-light-alpha;
                }

                &:nth-child(even) {
                    background-color: $blue-light;
                }
            }
        }
    }
}
