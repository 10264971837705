.grid {
    display: grid;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.sm\:grid-cols-2 {
    @media (min-width: $screen-sm-min) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.sm\:grid-cols-3 {
    @media (min-width: $screen-sm-min) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.gap-x-8 {
    column-gap: 2rem;
}

.gap-y-4 {
    row-gap: 1rem;
}
