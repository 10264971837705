.location-hero {
    background-size: cover;
    background-position: center;
    height: 36rem;

    h1 {
        font-size: 2.25rem;

        @media (min-width: $screen-md-min) {
            font-size: 3.75rem;
        }
    }

    p {
        font-size: 0.875rem;

        @media (min-width: $screen-md-min) {
            font-size: 1.125rem;
        }
    }

    .location-hero-content {
        color: $white;
        line-height: 2.5em;
    }

    .dispensary-lookup-form {
        display: flex;
        flex-direction: column;

        > * + * {
            margin-top: 1rem;
        }

        @media (min-width: $screen-sm-min) {
            flex-direction: row;
            justify-content: center;

            > * + * {
                margin-top: 0;
                margin-left: 2rem;
            }

            input {
                width: 23rem;
            }
        }
    }
}

.location-hero-grower {
    background: $grey-light url(../images/grower_image.jpg) no-repeat top center;
}

.location-hero-dispensary {
    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0) 100%
        ),
        url(../images/dispensary_image.jpg);
}

.locations-list-container {
    margin-bottom: 5rem;

    h1 {
        font-size: 2.25rem;

        @media (min-width: $screen-md-min) {
            font-size: 3.75rem;
        }
    }

    .state-name {
        margin: 1.5rem 0;
    }

    .cities-wrapper {
        .page {
            margin: 1em 0 0 0;

            .city {
                display: inline-flex;
                width: 32%;
                height: 2em;
                margin-bottom: 1em;

                img {
                    height: 100%;
                }

                a {
                    display: inline-block;
                    line-height: 2em;
                    vertical-align: top;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        hr:last-child {
            display: none;
        }
    }

    .featured-wrapper {
        margin-top: 3rem;

        .featured-list {
            @media (min-width: $screen-md-min) {
                display: flex;

                > * + * {
                    margin-left: 2rem;
                }
            }
        }

        .slick-arrow {
            position: absolute;
            top: 40%;
            transform: translateY(-50%);
            z-index: 1;
            padding: 0;
        }

        .slick-prev {
            left: 1.5rem;
        }

        .slick-next {
            right: 1.5rem;
        }

        .slick-slide {
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .featured-item {
            border: 1px $grey solid;
            margin-bottom: 1rem;
            padding: 2rem;
            border-radius: 10px;

            @media (min-width: $screen-md-min) {
                width: 33.33%;
            }

            @media (min-width: $screen-lg-min) {
                width: 25%;
            }
        }

        .featured-header {
            margin-top: 1rem;
            font-size: 1.125em;
            font-weight: 600;
            text-align: center;
            border-bottom: solid 1px $grey;
            padding-bottom: 1rem;

            a {
                color: $black;
            }
        }

        .featured-detail-list {
            margin-top: 1rem;
        }

        .featured-detail {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            margin-bottom: 0.6rem;
            color: $grey-normal;

            i {
                margin-right: 1rem;
                color: $black;
                width: 1rem;
                flex-shrink: 0;
                display: flex;
                justify-content: center;
            }
        }

        .featured-detail-line-wrapper {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .featured-image {
            width: 100%;
            max-width: 100%;
            margin: auto;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .locations-wrapper {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(1, minmax(0, 1fr));

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }
}
