.btn {
    @extend %rounded-full;
    font-size: 0.875rem;
    cursor: pointer;

    &:disabled {
        cursor: default;
        opacity: 0.65;
    }

    &:focus {
        outline: none;
    }

    i {
        margin-left: 0.8em;
    }
}

// This button is filled green
.btn-primary {
    @extend %green-gradient-dark;
    border: 0;
    color: $white;
    opacity: 1;
    position: relative;
    z-index: 1;

    // workaround for transitioning background gradient in CSS
    &::before {
        border-radius: inherit;
        background: $green-dark;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: -1;
        transition: inherit;
        transition-property: opacity;
    }

    &:hover:not([disabled]) {
        &::before {
            opacity: 1;
        }
    }
}

.btn-secondary {
    background: $white;
    border: 1px solid $green-trees;

    &:hover:not([disabled]) {
        @extend %green-gradient-dark;
        border: $green-dark;
        color: $white;
    }

    &:disabled {
        color: $white;
        background: $grey;
        border: $grey;
    }
}
