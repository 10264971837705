html,
body {
    @extend %font-family-default;
    color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

h1 {
    margin-top: 2rem;
}

h1,
h2 {
    @extend %font-family-light;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1em;

    @media (min-width: $screen-sm-min) {
        font-size: 2.125rem;
    }
}

h3 {
    font-size: 0.875rem;
    font-weight: 400;
    color: $grey-normal;
}

h4 {
    font-size: 1.25rem;
    font-weight: 400;
}

a {
    color: $blue;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
        color: $cyan-blue;
        text-decoration: none;
    }
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

button,
input,
select {
    @extend %font-family-default;
}

.text {
    @extend %font-family-light;
    font-size: 0.9375rem;
    font-weight: 300;
    margin: 1rem 0;
}

.text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-md {
    font-size: 0.9375rem;
    line-height: 1.25rem;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
}

.text-5xl {
    font-size: 3rem;
    line-height: 1;
}

.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}

.text-7xl {
    font-size: 4.5rem;
    line-height: 1;
}

.leading-6 {
    line-height: 1.5rem;
}

.leading-7 {
    line-height: 1.75rem;
}

.leading-10 {
    line-height: 2.5rem;
}
