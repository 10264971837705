header {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.04);
    position: sticky;
    background-color: $white;
    top: 0;
    z-index: $z-index-nav;
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 1rem;
    height: 4rem;
    font-weight: 600;

    @media (min-width: $screen-md-min) {
        height: 5.625rem;
    }

    .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        max-width: 1900px;

        @media (min-width: $screen-md-min) {
            justify-content: space-between;
        }
    }

    a {
        color: $black-alpha-50;
    }

    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
    }

    .logo {
        width: 180px;
        margin-right: 0;

        @media (min-width: $screen-md-min) {
            width: 225px;
        }

        img {
            width: 100%;
            height: auto;
            vertical-align: middle;
        }
    }

    .separator {
        opacity: 0.2;
    }

    .mobile-menu-btn {
        @media (min-width: $screen-md-min) {
            display: none;
        }
    }

    .mobile-menu {
        .navbar {
            font-family: 'Raleway', sans-serif;
            font-weight: 500;
        }

        .favorites {
            display: block;
        }

        .login-menu {
            a {
                font-size: 0.875rem;
            }
        }

        .strain-search-form {
            display: block;
        }

        .mobile-nav {
            a {
                color: $white;
                font-size: 1.5rem;
            }
        }
    }

    // Main Navigation
    .nav-tabs-wrapper {
        display: none;

        @media (min-width: $screen-md-min) {
            display: flex;
        }

        a {
            font-size: 0.875rem;
            color: $black;
            opacity: 0.5;
            padding: 1rem;
        }

        .active {
            a {
                opacity: 1;
            }
        }
    }

    .strain-search-form {
        display: none;

        @media (min-width: $screen-xl-min) {
            display: block;
        }

        .form-group {
            margin-bottom: 0;
        }

        input.form-control[type='text'] {
            height: 2.5rem;
            border-color: $grey;
            border-width: 1px;
            padding-left: 2.25rem;
        }

        .payloads-region {
            font-size: 0.875rem;
        }
    }

    .favorites {
        display: none;

        @media (min-width: $screen-md-min) {
            display: block;
        }

        a {
            font-size: 1.25rem;
        }

        span[data-count]:after {
            position: absolute;
            right: 25%;
            top: 8%;
            content: attr(data-count);
            font-size: 0.75em;
            padding: 0.25em;
            border-radius: 25%;
            line-height: 0.75em;
            color: $white;
            background: $input-icon-green;
            text-align: center;
        }
    }

    .login-menu {
        a {
            font-size: 0.875rem;
        }
    }

    // Authentication Menu
    .nav-list-wrapper {
        position: relative;
    }

    .nav-list {
        display: none;
        list-style: none;
        font-size: 0.875rem;

        @media (min-width: $screen-md-min) {
            display: block;
        }
    }

    .auth-menu {
        a {
            color: $black;
        }

        .popup-menu-content {
            position: absolute;
            right: 0;
            top: 3rem;
            width: 20rem;
            background: $white;
            text-align: right;
            z-index: $z-index-popup;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding-left: 0;

            .action {
                display: block;
                padding: 0.5em 1.5em;
            }

            .location {
                background: $blue-light;
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;

                span {
                    display: block;
                }
            }
        }
    }
}
