.profile-header {
    .user-image-wrapper {
        width: 4.375rem;
        height: 4.375rem;
        flex-shrink: 0;

        .upload-image {
            min-width: auto;
            cursor: pointer;
        }

        .upload-image-label {
            background: url('data:image/svg+xml;utf8, <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 12.6662V16H3.3338L13.1707 6.16306L9.83693 2.82927L0 12.6662Z" fill="white"/><path d="M15.74 2.33587L13.6641 0.260036C13.3174 -0.0866787 12.7528 -0.0866787 12.4061 0.260036L10.7792 1.88693L14.113 5.22073L15.7399 3.59383C16.0867 3.24712 16.0867 2.68258 15.74 2.33587Z" fill="white"/></svg>')
                no-repeat center;
            appearance: none;
            margin: 0;
            opacity: 0;
            transition: opacity 0.15s;

            &:hover {
                opacity: 1;
            }
        }
    }

    .profile-tabs {
        display: none;
        @media (min-width: $screen-xs-min) {
            display: flex;
        }
    }

    .profile-tabs-mobile {
        select {
            height: 2.5rem;
        }
    }
}

.profile-content {
    margin-bottom: 2rem;

    @media (min-width: $screen-sm-min) {
        margin-top: 3.75rem;
        margin-bottom: 5rem;
    }
}

.user-profile {
    select {
        appearance: none;
        height: 3.125rem;
        cursor: pointer;
    }

    fieldset {
        margin: 0;
        padding: 0;
    }

    input[type='text'],
    input[type='email'],
    select {
        height: 3.125rem;
    }

    .user-info {
        section {
            margin-bottom: 2.5rem;
        }

        .section-title {
            color: $black;
            font-weight: 600;
            margin-bottom: 0.75rem;
        }
    }

    .receive-text {
        label {
            display: flex;
        }
    }

    .form-field {
        label {
            color: $grey-normal;
        }
    }

    .user-change-pwd {
        button,
        input {
            width: 100%;
        }

        input {
            @media (min-width: $screen-xs-min) {
                max-width: 23.125rem;
            }
        }

        button {
            @media (min-width: $screen-xs-min) {
                max-width: 10.625rem;
            }
        }
    }

    .favorites-container {
        @media (min-width: $screen-sm-min) {
            display: flex;
            align-items: flex-start;
        }
    }

    .sub-tabs {
        flex-direction: row;
        justify-content: space-between;

        @media (min-width: $screen-sm-min) {
            flex-direction: column;
            margin-right: 5rem;
        }

        li {
            cursor: pointer;
            padding: 0.5rem 0;
            display: flex;
            align-items: center;
            position: relative;
            margin-left: 0.5rem;
            font-size: 0.875rem;

            @media (min-width: $screen-sm-min) {
                margin-left: 2rem;
                font-size: 1.125rem;
            }
        }

        li.active {
            color: $black;

            &::before {
                content: '';
                position: absolute;
                left: -0.5rem;
                width: 5px;
                height: 30px;
                @extend %green-gradient-dark;

                @media (min-width: $screen-sm-min) {
                    left: -2rem;
                }
            }
        }
    }

    .favorites-tabs-mobile {
        select {
            height: 2.5rem;
        }
    }

    .favorite,
    .review {
        .strain-image {
            width: 5rem;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .favorite {
        display: flex;
        align-items: flex-start;
        padding: 1rem 0;

        @media (min-width: $screen-sm-min) {
            align-items: center;
        }

        .strain-info-wrapper {
            @media (min-width: $screen-sm-min) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            i {
                width: 1.5rem;
            }
        }

        .rating-stars,
        .created-date {
            margin-top: 1rem;

            @media (min-width: $screen-sm-min) {
                text-align: right;
            }
        }
    }

    .review {
        > * + * {
            margin-top: 2.5rem;
        }

        @media (min-width: $screen-sm-min) {
            display: flex;

            > * + * {
                margin-top: 0;
                margin-left: 2.5rem;
            }
        }

        .strain-info-wrapper {
            @media (min-width: $screen-sm-min) {
                width: 30%;
                max-width: 25rem;
            }
        }

        .strain-review-wrapper {
            .user-rating {
                padding-left: 0;
                transform-origin: top left;
                transform: scale(0.7);
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .user-proximity-settings {
        button {
            width: 100%;

            @media (min-width: $screen-xs-min) {
                width: 10.625rem;
            }
        }
    }
}
