.kiosk-header {
    height: 12.5rem;
    margin-bottom: 8rem;
    background-image: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.8) 0%,
            rgba(0, 0, 0, 0.4) 100%
        ),
        url(../images/kiosk/kiosk-header.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 3rem;

    & + .main-region {
        .back-to-search-wrapper,
        .search-results-container {
            margin-top: 0;
        }
    }

    .kiosk-menu {
        justify-content: space-around;

        @media (min-width: $screen-xs-min) {
            justify-content: flex-end;
            padding: 0 2rem;

            > * + * {
                margin-left: 1.5rem;
            }
        }
    }

    .dispensary-logo {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        text-align: center;
        border-radius: 10px;
        border: 2px solid $white;

        img {
            width: 140px;
            height: 140px;
            object-fit: cover;
            border-radius: inherit;

            @media (min-width: $screen-xs-min) {
                width: 170px;
                height: 170px;
            }
        }
    }

    .header-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2.5rem;
        width: 8.5rem;

        background: rgba(0, 0, 0, 0.5);
        color: $white;
        border: 1px solid rgba(256, 256, 256, 0.5);
        transition: 0.15s;

        @media (min-width: $screen-xs-min) {
            width: 9rem;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }
    }

    .header-btn-white {
        background: $white;
        color: $black;
        border: 0;

        &:hover {
            background: rgba(256, 256, 256, 0.7);
        }
    }

    .add-favorite {
        width: 2.5rem;

        i {
            font-size: 1.25rem;
            margin: 0;
        }
    }

    & + main {
        #loading-spinner-new {
            padding-top: 13rem;

            @media (min-height: 600px) {
                padding-top: 11rem;
            }

            @media (min-height: 700px) {
                padding-top: 5rem;
            }
        }
    }
}
