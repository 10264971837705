.pager {
    margin: 1.5rem 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    font-weight: 600;
    margin-left: -0.4rem;

    & > * {
        margin-left: 0.4rem;
        margin-bottom: 0.4rem;
    }

    .page-letter {
        min-width: 2rem;
        height: 2rem;
        background-color: $blue-light;
        border-radius: 5px;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $black;
            padding: 0.5rem;
            width: 100%;
            height: 100%;
        }
    }

    .page-letter.active {
        background-color: $green-light;

        a {
            color: $white;
        }
    }
}
