.sign-up-b2b-wrapper {
    padding: 0 1em 3em;
    margin-top: -2em;
}

.b2b-sign-up-wrapper {
    .wizard-steps-wrapper {
        --circle-size: 1.75rem;
        font-size: 0.875rem;

        @media (min-width: $screen-xs-min) {
            --circle-size: 3.125rem;
            font-size: 1.25rem;
        }
    }

    h1 {
        margin-bottom: 1.5rem;
    }

    fieldset {
        > * + * {
            margin-top: 1rem;
        }
    }

    .error-message,
    .subtitle {
        text-align: center;
    }

    .business-type {
        display: flex;
        flex-direction: column;

        @media (min-width: $screen-xs-min) {
            flex-direction: row;
            justify-content: center;

            > * + * {
                margin-left: 2rem;
            }
        }
    }

    .address-field,
    .phone-field {
        width: 100%;

        @media (min-width: $screen-xs-min) {
            width: 23.125rem;
        }
    }

    .image-upload-form {
        .upload-image {
            opacity: 0;
            width: 0;
            height: 0;
            min-width: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        .image-upload-buttons {
            flex-direction: column;

            > * + * {
                margin-top: 1em;
            }

            @media (min-width: $screen-xs-min) {
                flex-direction: row;
                > * + * {
                    margin-top: 0;
                    margin-left: 1.5em;
                }
            }

            button {
                width: 100%;

                @media (min-width: $screen-xs-min) {
                    width: 10.5rem;
                }
            }
        }
    }
}

.privacy-dialog,
.terms-dialog {
    margin-bottom: 4rem;
}
