.business-location-holder {
    min-height: 42rem;

    h1 {
        font-size: 1.5rem;

        @media (min-width: $screen-sm-min) {
            font-size: 3.75rem;
        }
    }

    .back-to-search-wrapper {
        margin-bottom: 1em;
        position: absolute;
        top: -1.5em;
    }

    .header-content {
        > * + * {
            padding-top: 2rem;
        }

        @media (min-width: $screen-sm-min) {
            display: flex;

            > * + * {
                padding-top: 0;
                padding-left: 2.5rem;
            }
        }
    }

    .show-menu-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10.625rem;

        @media (min-width: $screen-sm-min) {
            display: none;
        }
    }

    .image-wrapper {
        .verified-badge {
            flex-grow: 1;
            margin-bottom: 0.5rem;
            padding: 0.5rem;
        }

        @media (min-width: $screen-sm-min) {
            width: 35%;
            max-width: 30rem;
            flex-shrink: 0;
        }
    }

    .location-logo {
        width: 100%;
        height: auto;
    }

    .anchor {
        top: -80px;
    }

    .btn-get-directions {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 10.625rem;
        }

        .fa {
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    .rating-row {
        justify-content: center;

        @media (min-width: $screen-sm-min) {
            justify-content: flex-start;
        }
    }

    .additional-info-wrapper {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 16rem;
        }
    }

    .hours-info {
        .schedule {
            font-size: 0.875rem;
            font-weight: 400;
        }

        .day-wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.3rem;
        }

        .day-name {
            text-transform: uppercase;
        }

        .first {
            color: $black;
            font-weight: 600;
        }
    }

    .contact-info {
        .fa {
            color: $blue;
            width: 1rem;
            margin-right: 1rem;
            text-align: center;
        }

        a {
            color: $grey-normal;
            font-weight: 600;
        }
    }

    .avg-price {
        margin-bottom: 1.25rem;
    }

    .avg-price-indicator {
        display: flex;
        cursor: pointer;
        height: 6px;

        .point {
            flex-grow: 1;
            border-left: 1px solid $grey;
        }

        .point:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-left: 0;
        }

        .point:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }

    .dispensary-reviews-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: $screen-md-min) {
            flex-direction: row;
            align-items: flex-start;

            > * + * {
                margin-left: 3.5rem;
            }
        }
    }

    .rate-location-form {
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: auto;
        }
    }

    .all-reviews-wrapper {
        margin-bottom: 2em;

        .back-btn-wrapper {
            display: block;
            position: absolute;
            top: -5em;
            color: $grey-normal;
        }

        .header {
            color: $grey-normal;
            margin-bottom: 3em;
        }

        .review-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 2em;

            .user-info {
                display: inline-block;
                position: relative;
                width: 6em;
                text-align: left;

                .user-image {
                    display: inline-block;
                    width: 4em;
                    height: 4em;
                    margin: 0 auto;
                    border: 1px solid $grey-light;
                    border-radius: 4em;

                    img {
                        width: 3em;
                        height: 3em;
                        margin: 0.3em 0.4em 0.4em 0.46em;
                    }
                }
            }

            .review {
                display: inline-block;
                width: 89%;
                vertical-align: top;

                .review-header {
                    .user-name {
                        font-weight: bold;
                        color: $grey-dark;
                    }

                    .rating {
                        display: inline-block;
                    }

                    .created-date {
                        font-weight: bold;
                        text-decoration: underline;
                        float: right;
                    }
                }

                .review-text {
                    margin-top: 0.5em;
                    color: $grey-normal;

                    .display {
                        display: block;
                        height: auto;
                        line-height: 1em;
                        overflow: hidden;
                        word-break: break-all;
                    }
                }
            }
        }

        .navigation-controls {
            display: block;
            position: relative;
            width: 100%;
            font-size: 1.5em;
            margin: 2.5em auto;
            height: 1em;

            a {
                color: $grey-normal;
            }

            .left-side {
                display: inline-block;
                position: absolute;
                left: 1em;
            }

            .right-side {
                display: inline-block;
                position: absolute;
                right: 1em;
            }
        }
    }
}

.phone-dialog {
    text-align: center;
}

#match-info-dialog {
    p {
        margin-bottom: 2em;
    }
}
