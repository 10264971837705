.w-100 {
    width: 100%;
}

.w-1\/3 {
    width: 33.3333%;
}

.w-4 {
    width: 1rem;
}

.w-6 {
    width: 1.5rem;
}

.w-8 {
    width: 2rem;
}

.w-10 {
    width: 2.5rem;
}

.w-12 {
    width: 3rem;
}

.w-14 {
    width: 3.5rem;
}

.w-16 {
    width: 4rem;
}

.w-20 {
    width: 5rem;
}

.w-24 {
    width: 6rem;
}

.w-32 {
    width: 8rem;
}

.w-36 {
    width: 9rem;
}

.w-42 {
    width: 10.5rem;
}

.w-48 {
    width: 12rem;
}

.w-56 {
    width: 14rem;
}

.w-64 {
    width: 16rem;
}

.w-72 {
    width: 18rem;
}

.w-80 {
    width: 20rem;
}

.w-96 {
    width: 24rem;
}

.w-screen {
    width: 100vw;
}

.h-auto {
    height: auto;
}

.h-100 {
    height: 100%;
}

.h-6px {
    height: 6px;
}

.h-2 {
    height: 0.5rem;
}

.h-6 {
    height: 1.5rem;
}

.h-8 {
    height: 2rem;
}

.h-10 {
    height: 2.5rem;
}

.h-12 {
    height: 3rem;
}

.h-13 {
    height: 3.25rem;
}

.h-14 {
    height: 3.5rem;
}

.h-16 {
    height: 4rem;
}

.h-20 {
    height: 5rem;
}

.h-24 {
    height: 6rem;
}

.h-32 {
    height: 8rem;
}

.h-36 {
    height: 9rem;
}

.h-56 {
    height: 14rem;
}

.h-72 {
    height: 18rem;
}

.h-screen {
    height: 100vh;
}

.min-w-0 {
    min-width: 0;
}

.max-w-xs {
    max-width: 20rem;
}

.max-w-7xl {
    max-width: 80rem;
}

.max-w-prose {
    max-width: 72ch;
}
