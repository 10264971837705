.history {
    position: relative;
    margin-top: 2.5rem;
    text-align: center;
    display: none;

    @media (min-width: 1191px) {
        display: block;
    }

    .history__line {
        @extend %green-gradient-dark;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        z-index: 0;
        width: 4px;
        background-color: #00ff00;
    }

    .history__container {
        display: inline-block;
    }

    .history__century {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .history__button {
        color: $white;
        height: 1.875rem;
        width: 10.625rem;
        font-size: 0.875rem;
        font-weight: 600;
        border-radius: 9999px;
        @extend %green-gradient-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        z-index: 10;

        @media (max-width: 1191px) {
            margin-bottom: 1rem;
        }
    }

    .history__card {
        background-color: $blue-light;
        border: 0;
        border-radius: 0.625rem;
        width: 360px;
        position: relative;
        text-align: left;
        margin-bottom: 1.5rem;

        @media (max-width: 1191px) {
            margin: 0 auto 1rem;
            width: 20.625rem;
        }

        img {
            max-width: 100%;
            border-bottom-left-radius: inherit;
            border-bottom-right-radius: inherit;
        }
    }

    .history__card_left:before {
        content: '';
        position: absolute;
        width: 36px;
        height: 12px;
        background: url('../images/history/history-connector.svg') no-repeat;
        top: 22px;
        right: -26px;

        @media (max-width: 1191px) {
            display: none;
        }
    }

    .history__card_right:before {
        content: '';
        position: absolute;
        width: 36px;
        height: 12px;
        background: url('../images/history/history-connector.svg') no-repeat;
        top: 22px;
        left: -20px;

        @media (max-width: 1191px) {
            display: none;
        }
    }

    .history__container_left {
        margin-right: 26px;
    }

    .history__descript {
        font-size: 0.875rem;
        padding: 1.25rem;
        color: $grey-normal;
        line-height: 1.3;
    }
}

.history_adaptive {
    display: block;
    margin-top: 1.5rem;

    @media (min-width: 1191px) {
        display: none;
    }
}
