$font-path: '../fonts';

@font-face {
    font-family: 'gotham_boldregular';
    src: url('#{$font-path}/gotham_bold-webfont.woff2') format('woff2'),
        url('#{$font-path}/gotham_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham_bookregular';
    src: url('#{$font-path}/gotham_book-webfont.woff2') format('woff2'),
        url('#{$font-path}/gotham_book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham_lightregular';
    src: url('#{$font-path}/gotham_light-webfont.woff2') format('woff2'),
        url('#{$font-path}/gotham_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'gotham_mediumregular';
    src: url('#{$font-path}/gotham_medium-webfont.woff2') format('woff2'),
        url('#{$font-path}/gotham_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'proxima_regular';
    src: url('#{$font-path}/proxima/ProximaNova-Regular.woff2') format('woff2'),
        url('#{$font-path}/proxima/ProximaNova-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'proxima_light';
    src: url('#{$font-path}/proxima/ProximaNova-Alt-Light.woff2')
            format('woff2'),
        url('#{$font-path}/proxima/ProximaNova-Alt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url('#{$font-path}/Raleway.woff2') format('woff2'),
        url('#{$font-path}/Raleway.woff') format('woff');
    font-display: swap;
}
