.advanced-search {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * + * {
        margin-top: 3rem;
    }

    @media (min-width: $screen-md-min) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        > * + * {
            margin-top: 0;
            margin-left: 3rem;
        }
    }

    h3 {
        font-size: 1.125rem;
    }

    &-section {
        width: 100%;

        @media (min-width: $screen-md-min) {
            width: 33.33%;
        }
    }

    .form-field-group {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr;

        @media (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-flow: column;
        }

        label {
            display: flex;
            margin-bottom: 0;
        }

        input:checked + label {
            color: $black;
        }
    }

    .general-group {
        @media (min-width: $screen-sm-min) {
            grid-template-rows: repeat(3, 1fr);
        }
    }

    .cannabinoids-group {
        @media (min-width: $screen-sm-min) {
            grid-template-rows: repeat(4, 1fr);
        }

        .form-field-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (min-width: $screen-sm-min) {
                flex-direction: column;
            }

            > span {
                width: 8rem;

                @media (min-width: $screen-sm-min) {
                    width: auto;
                    margin-bottom: 0.5rem;
                }
            }

            input:not([type='checkbox']) {
                min-width: auto;
                border-radius: 5px;
                border: 0;
                background-color: $blue-light-alpha;
                line-height: 3;
                padding-left: 0.4rem;
                font-weight: 600;
                border: 1px solid transparent;

                &:focus {
                    background-color: $white;
                    border: 1px solid $green-light;
                }

                &::placeholder {
                    font-size: 0.75rem;
                    font-weight: 400;
                    color: $grey-light;
                }
            }
        }

        .percentage-addon {
            position: relative;

            &::before {
                content: '%';
                position: absolute;
                color: $grey-light;
                right: 0.4rem;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
            }
        }
    }

    .terpenes-group {
        @media (min-width: $screen-sm-min) {
            grid-template-rows: repeat(8, 1fr);
        }
    }
}
